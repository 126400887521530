@import "@/variables.scss";

.cards-wrapper {
  gap: 10px;
}
.card {
  display: flex;
  justify-content: center;
}

.card-title-hosp {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
}

.destination-img {
  margin-bottom: 20px;
}

.card {
  border: none;
  &.shadow {
    // border: 1px solid red;
    box-shadow: 0px 4px 20px 0 rgb(148 148 148 / 24%) !important;
  }
}

.outer {
  // border: 1px solid $blue;
  flex-grow: 1;
}

.dotted-line {
  white-space: nowrap;
  position: relative;
  overflow: hidden;

  .outer:nth-child(odd) {
  }
  .outer:nth-child(even) {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
}

.dotted-line::after {
  content: "..........................................................................................................";
  letter-spacing: 6px;
  font-size: 30px;
  color: #9cbfdb;
  display: inline-block;
  vertical-align: 3px;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .cards-wrapper {
    flex-direction: column;
    gap: 1.5em;
  }
  .tourism-card {
    width: 160px !important;
    height: 200px !important;
}
}