@import "./variables.scss";
.App {
    text-align: center;
    overflow: hidden;
    background-color: #fff;
}

.terms {
    text-align: left;
}

.bi_arrow {
    color: #2bace2;
    width: 30px !important;
}

.progressBar {
    margin-bottom: 3em;
    height: 7px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--mo-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background: linear-gradient(270deg, #43c567 0%, #2bace2 100%);
    transition: var(--mo-progress-bar-transition);
}

.partnerCarouselCard {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 212px;
    border-radius: 10px;
}

.cr_shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 15px;
}

.custom-carousel-control {
    background-color: #14131350;
    opacity: 0.8;
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

.custom-carousel-control:hover {
    background-color: #141313b6;
}

.terms h2,
.terms h3 {
    color: #2bace2;
    padding: 10px 0px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.faqBtn {
    background-color: white;
    border: none;
    color: #2bace2;
    width: 185px;
    padding: 10px 0px;
    display: block;
    margin: 0 auto;
    font-weight: 600;
    margin-top: 0rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// slick slider css
.slider_content {
    text-align: center;
    margin-bottom: 30px;
}

.slider_header {
    font-size: 42px;
}

.slider_container .img {
    display: flex;
    margin: 0 auto;
    height: auto;
    width: 60%;
    border-radius: 5%;
}

.slider_testimonial {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #fff;
    margin: 1rem;
    height: 280px;
}

.slider_content {
    .slick-track {
        align-items: flex-start;
    }
}

.slider_testimonial .img {
    border: 1px solid var(--green);
    border-radius: 90px;
    margin-top: -80px;
    width: 130px;
    height: 130px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slider_award_container .img {
    display: flex;
    margin: 0 auto;
    height: 270px;
    width: 95%;
}

.slider_award .img {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.slider_testimonial p {
    padding: 0px 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    white-space: pre-wrap;
}

.slider_testimonial h2 {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
}

.slider_container div {
    display: flex;
    align-items: center;
}

.slider_container {
    margin: 0 auto;
    width: 100%;
    // height: 80vh;
    border-color: white;
}

.slider_title {
    margin-left: 50px;
    width: 70%;
    font-size: 30px;
    padding: 20px;
    border-radius: 5px;
    font-size: 33px;
}

.slider_description {
    margin: 20px auto;
    width: 80%;
    font-size: 25px;
    margin: 20px auto;
}

.slick-prev {
    left: -52px !important;
}

.slick-next:before,
.slick-prev:before {
    content: "" !important;
}

.next-slick-arrow,
.prev-slick-arrow {
    color: #000000;
    font-size: 45px;
}

// slick slider css
a.link {
    font-size: 12px;
    text-decoration: underline;
    color: var(--blue);
}

.myfield {
    >fieldset {
        >legend {
            border: 1px solid;
            display: none;
        }
    }
}

img {
    max-width: 100% !important;
}

.ant-select-dropdown {
    z-index: 1056;
}

.ant-select-selector {
    max-height: 200px;
    width: 368px;
    overflow-y: auto;
}

.ant-select-selection-placeholder {
    color: var(--mo-body-color) !important;
    text-align: left;
    font-family: Montserrat, sans-serif;
}
  .custom-dropdown .dropdown-menu {
    height: 180px;
    overflow-y: auto;
  }
  .custom-dropdown .dropdown-item {
    padding: 0px 20px;
    height: 35px;
    display: flex;
    align-items: center;
  }
  .document_anchor:hover {
    color: #0d6efd;
    text-decoration: underline;
  }
  .contact_us_container{
    padding: 5em 2em;
    margin-right: 2em;
  }
  .form_wizards{
    width: 857px;
    margin: 0 auto;
  }
//   common styles for mobile device
  @media (max-width: 768px) {
    .rs_socialLinks{
        gap: 15px !important;
        margin-bottom: 2em;
        & img{
            width: 30px;
            height: 30px;
        }
    }
    .about_us_div{
        padding: 0px 2em;
    }
    .contact_us_container{
        padding: 1em 0em;
        margin-right: 0em;
        margin: 0px 10px;
        & p{
            text-align: left;
        }
      }
      .terms {
        text-align: left;
        padding: 0em 2em;
    }
    .form_wizards{
        width: auto;
        margin: 0;
      }
      .hospital_form_row{
        padding: 2em;
      }
      .terms_faq .card-body{
        text-align: justify;
      }
      .mob_nav{
        gap: 1em;
        text-align: left;
        padding: 5%;
      }
  }