body {
  --ck-color-base-border: var(--blue);
  --ck-border-radius: 10;
  --ck-color-toolbar-border: var(--blue);
}

.ag-theme-pmt {
  /* customise with CSS variables */
  --ag-grid-size: 8px;
  --ag-border-color: transparent;
  --ag-header-background-color: transparent;
  .ag-header {
    text-transform: uppercase;
  }

  .ag-header-cell-text {
    color: var(--blue);
    font-weight: 600;
  }

  .ag-row {
    border-bottom: 1px solid #e2e8f0;
  }
  .ag-cell {
    text-align: left;
  }
}

