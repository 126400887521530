@import "@/variables.scss";

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
.banner,
.banner-web {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 324px;
  overflow: hidden;
  color: white;
  border-radius: 0px;
  z-index: 0;
  .carousel {
    max-height: 324px;
  }

  .text-wrapper {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
  }
  .line-1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
  .line-2 {
    font-size: 16px;
    line-height: 20px;
  }
  button {
    background-color: white;
    border: none;
    color: $blue;
    width: 185px;
    height: 48px;
    &:hover {
      background-color: white;
      color: $blue;
    }
  }

  @media (min-width: 425px) {
    border-radius: 10px;
  }
}

.banner-web {
  height: 320px;
  border-radius: 10px;
  .line-1 {
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
  }
  .line-2 {
    font-size: 48px;
    line-height: 59px;
  }
}

.tourism-card {
  width: 93px;
  height: 93px;
}

@include media-breakpoint-up(sm) {
  .tourism-card {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 768px) {
  .banner_btn{
    width: 120px !important;
    height: 32px !important;
    font-size: 14px;
  }
  .banner,
  .banner-web {
    height: auto !important;
  }
}
