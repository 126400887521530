//colors
$blue: #2bace2;
$green: #89c543;
$black: #2f2f2f;

:root {
  --blue: #2bace2;
  --green: #89c543;
  --black: #2f2f2f;
}
