@import "@/variables.scss";
import .custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(43,172,226, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border: none;
  outline-width: 0;
  :active {
    outline-width: 0;
  }
}

.custom-toggler.navbar-toggler {
  outline: none !important;
  box-shadow: none;
}

a.navlink {
  // display: block;
  min-height: 30px;
}
a.navlink.active {
  background: no-repeat bottom left;
  color: $green !important;
  padding: 5px 0;
}

@media (min-width: 425px) {
  a.navlink.active {
    background: no-repeat bottom left;
    color: $green !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='29' height='4' viewBox='0 0 29 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2 2L20 2' stroke='%2389C543' stroke-width='3' stroke-linecap='round'/%3e%3cpath d='M26 2H27' stroke='%2389C543' stroke-width='3' stroke-linecap='round'/%3e%3c/svg%3e ");
    padding: 5px 0;
    font-weight: 700;
  }
}

.nav-item {
  margin: 0 10px;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.navbar .dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1;
  padding: 0;
  font-size: 14px;
  .dropdown-item {
    padding: 15px 20px;
    border-bottom: 1px solid #cacaca !important;

    &.active,
    &:active {
      background-color: var(--green);
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.menu-dropdown {
  ul { 
    list-style-type: none;
    margin: 0; padding: 0; 
    &:first-child > li { 
      float: left; height: 4em;    
     }
  }
  
  ul > li > ul { display: none; }
  
  li { cursor: pointer; } 
  li:hover > ul 
  { 
    display: block;
    position: relative;
    left: 13.7em;
    bottom: 4em;
    background: #fff;
    border: 1px solid #cacaca;
    border-radius: 5px;
  }
}

