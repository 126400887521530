.top-bar {
  display: flex;
}
.contacts-section {
  display: flex;
}

.icon-link {
  padding: 10px;
  font-size: 14px;
}

.icon-link > a {
  display: flex;
  gap: 10px;
}
