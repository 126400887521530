

  #gallery img {
    width:100%;
    margin: 4% auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
/* Hover effect */
.image-wrapper.hovered {
    transform: scale(1.1); /* Increase the scale to create a zoom effect */
    z-index: 1;
  }
  
  /* Expand effect */
  .image-wrapper.expanded {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5); /* Increase the scale to make it larger */
    z-index: 2;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }