#container {
  margin: 1rem;
}

.items {
  margin-bottom: 1rem;
}

.paginate {
  display: flex;
  justify-content: center;
}
