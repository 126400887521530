@import "@/variables.scss";

input {
  outline: none;
}
.search-wrapper {
  width: 290px;
  height: 34px;
  position: relative;
  border: 2px solid $blue;
  border-radius: 10px;

  > * {
    position: absolute;
  }
}
